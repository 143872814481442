<template>
  <section>
    <the-overlay />
    <div class="new-invite-modal">
      <app-modal
        @close="close"
        paddingless
        backdrop-click-close
        modal-size="md"
      >
        <template slot="header" v-if="!isLoading">
          <div class="new-invite-modal__header">
            {{ $t(`${langPath}.title`) }}
          </div>
        </template>

        <app-modal-container>
          <invites-new
            v-if="candidate"
            class="new-invite-modal__form"
            variant="inverted"
            :candidate="candidate"
            :show-title="false"
            :success-redirect="false"
            :invite-template-id="inviteTemplateFromCollection"
            @success="onSuccess"
          />
        </app-modal-container>
      </app-modal>
    </div>
  </section>
</template>

<script>
import InvitesNew from './InvitesNew'
import { TheOverlay } from '@revelotech/everest'
import { CANDIDATES, INVITE_TEMPLATES, CURRENT_USER } from '@/store/namespaces'
import { createNamespacedHelpers } from 'vuex'
import { companiesUrl } from '@/helpers/constants/external-urls'

const candidatesHelper = createNamespacedHelpers(CANDIDATES)
const inviteTemplatesHelper = createNamespacedHelpers(INVITE_TEMPLATES)
const currentUserHelper = createNamespacedHelpers(CURRENT_USER)

export default {
  components: {
    InvitesNew,
    TheOverlay
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    ...candidatesHelper.mapState(['isFetchingCandidate']),
    ...candidatesHelper.mapGetters(['getCandidateById']),
    ...inviteTemplatesHelper.mapState([
      'isFetchingAts',
      'isFetchingInviteTemplate'
    ]),
    candidateId () {
      return this.$route.params.candidateId
    },
    candidate () {
      return this.getCandidateById(this.candidateId)
    },
    inviteTemplateFromCollection () {
      return Number(this.$route.query.inviteTemplateId)
    },
    isLoading () {
      return this.isFetchingAts ||
             this.isFetchingCandidate ||
             this.isFetchingInviteTemplate
    }
  },
  async created () {
    await this.getCurrentUser()
    this.getCandidate({ candidateId: this.candidateId })
  },
  mounted () {
    this.notifyParentComponentReady()
  },
  methods: {
    ...candidatesHelper.mapActions(['getCandidate']),
    ...currentUserHelper.mapActions(['getCurrentUser']),
    notifyParentComponentReady () {
      window.parent.postMessage({
        action: 'readyInvitesNewModal'
      }, companiesUrl)
    },
    close () {
      window.parent.postMessage({
        action: 'closeInvitesNewModal'
      }, companiesUrl)
    },
    onSuccess (url) {
      if (url) {
        window.parent.postMessage({
          action: 'redirectToInviteSent',
          url
        }, companiesUrl)
      } else {
        this.close()
      }
    }
  }
}
</script>

<style lang="scss">
body {
  background: transparent;
}

.new-invite-modal {
  $padding: 4*$base;

  &__header {
    padding: $padding;
  }

  &__form {
    padding: 0 $padding;

    .invite-form {
      border-radius: $app-border-radius;
      overflow: hidden;
    }
  }
}
</style>
